<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col class="text-center text-h4 col-12">Сотрудничество</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Пользователи
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="refferals"
            :loading="loading"
            loading-text="Загрузка... Пожалуйста подождите"
            :items-per-page="10"
          >
            <template v-slot:item.user="{ item }">
              <router-link tag="div" :to="`/users/${item.id}`">
                <v-btn color="primary"> {{ item.nick }}</v-btn>
              </router-link>
            </template>
            <template v-slot:item.totalBalanceByReferals="{ item }">
              {{
                (item.totalBalanceByReferals / 100).toFixed(2)
                  | number("0,0", { thousandsSeparator: " " })
              }}
              ₽
            </template>
            <template v-slot:item.balance="{ item }">
              {{
                (item.balance / 100).toFixed(2)
                  | number("0,0", { thousandsSeparator: " " })
              }}
              ₽
            </template>
            <template v-slot:item.balanceByReferals="{ item }">
              {{
                (item.balanceByReferals / 100).toFixed(2)
                  | number("0,0", { thousandsSeparator: " " })
              }}
              ₽
            </template>
            <template v-slot:item.isFake="{ item }">
              <v-simple-checkbox color="green" :value="item.is_fake" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ReferalOrders />
  </v-container>
</template>

<script>
import UsersService from "@/services/users.service";
import ReferalOrders from "@/views/pages/Referals/referalOrders.vue";
// import ReferalsService from "@/services/referals.service";

export default {
  name: "Index",
  components: {
    ReferalOrders
  },
  data: () => ({
    headers: [
      { text: "id", value: "id" },
      { text: "Пользователь", value: "user" },
      { text: "Всего получено", value: "totalBalanceByReferals" },
      { text: "На текущий момент", value: "balanceByReferals" },
      { text: "Баланс", value: "balance" },
      { text: "Ютубер", value: "isFake" }
    ],
    search: "",
    refferals: [],
    loading: false
  }),
  methods: {
    loadRefferals() {
      this.loading = true;
      UsersService.findMany({
        where: {
          totalBalanceByReferals: {
            gte: 100
          }
        },
        orderBy: {
          totalBalanceByReferals: "desc"
        },
        // orderBy: [{ state: "asc" }, { id: "desc" }],
        take: 500
      }).then(data => {
        this.refferals = data;
        this.loading = false;
      });
    }
  },
  async created() {
    this.loadRefferals();
    // console.log(await ReferalsService.getOrders());
  }
};
</script>

<style scoped></style>
