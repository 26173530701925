<template>
  <v-container fluid>
    <v-col class="text-center text-h4 col-12"
      >Конвертация партнерских средств
    </v-col>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-tabs v-model="tab">
              <v-tab>Созданные</v-tab>
              <v-tab>Одобренные</v-tab>
              <v-tab>Отменненые</v-tab>
            </v-tabs>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table
                  :headers="headersCreateOrder"
                  :items="orders.createdOrders"
                  :loading="loading"
                  :items-per-page="18"
                  loading-text="Загрузка... Пожалуйста подождите"
                >
                  <template v-slot:item.user="{ item }">
                    <router-link tag="div" :to="`/users/${item.cardId}`">
                      <v-btn color="primary"> {{ item.cardNick }}</v-btn>
                    </router-link>
                  </template>
                  <template v-slot:item.amount="{ item }">
                    <span class="font-weight-bold">
                      {{ (item.amount / 100).toFixed(2) }}
                    </span>
                  </template>
                  <template v-slot:item.createdAt="{ item }">
                    {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                  <template v-slot:item.updatedAt="{ item }">
                    {{ item.updated_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      color="success"
                      class="mr-1"
                      @click="successOrder(item)"
                      >Принять</v-btn
                    >
                    <v-btn color="red" @click="canceledOrders(item)">
                      Отменить</v-btn
                    >
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :headers="headers"
                  :items="orders.completedOrders"
                  :loading="loading"
                  :items-per-page="18"
                  loading-text="Загрузка... Пожалуйста подождите"
                >
                  <template v-slot:item.user="{ item }">
                    <router-link tag="div" :to="`/users/${item.cardId}`">
                      <v-btn color="primary"> {{ item.cardNick }}</v-btn>
                    </router-link>
                  </template>
                  <template v-slot:item.operator="{ item }">
                    <router-link tag="div" :to="`/users/${item.adminId}`">
                      <v-btn color="blue"> {{ item.adminNick }}</v-btn>
                    </router-link>
                  </template>
                  <template v-slot:item.amount="{ item }">
                    <span class="font-weight-bold">
                      {{ (item.amount / 100).toFixed(2) }}
                    </span>
                  </template>
                  <template v-slot:item.createdAt="{ item }">
                    {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                  <template v-slot:item.updatedAt="{ item }">
                    {{ item.updated_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :headers="headers"
                  :items="orders.canceledOrders"
                  :loading="loading"
                  :items-per-page="18"
                  loading-text="Загрузка... Пожалуйста подождите"
                >
                  <template v-slot:item.user="{ item }">
                    <router-link tag="div" :to="`/users/${item.cardId}`">
                      <v-btn color="primary"> {{ item.cardNick }}</v-btn>
                    </router-link>
                  </template>
                  <template v-slot:item.operator="{ item }">
                    <router-link tag="div" :to="`/users/${item.adminId}`">
                      <v-btn color="blue"> {{ item.adminNick }}</v-btn>
                    </router-link>
                  </template>
                  <template v-slot:item.amount="{ item }">
                    <span class="font-weight-bold">
                      {{ (item.amount / 100).toFixed(2) }}
                    </span>
                  </template>
                  <template v-slot:item.createdAt="{ item }">
                    {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                  <template v-slot:item.updatedAt="{ item }">
                    {{ item.updated_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReferalsService from "@/services/referals.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "referalOrders",
  data: () => ({
    initMoment: moment,
    tab: 0,
    loading: true,
    orders: {
      createdOrders: [],
      completedOrders: [],
      canceledOrders: []
    },
    headersCreateOrder: [
      { text: "ID", value: "id" },
      { text: "Пользователь", value: "user" },
      { text: "Сумма", value: "amount" },
      { text: "Дата создания", value: "createdAt" },
      { text: "Дата Последнего обновления", value: "updatedAt" },
      { text: "Действие", value: "action" }
    ],
    headers: [
      { text: "ID", value: "id" },
      { text: "Пользователь", value: "user" },
      { text: "Оператор", value: "operator" },
      { text: "Сумма", value: "amount" },
      { text: "Дата создания", value: "createdAt" },
      { text: "Дата Последнего обновления", value: "updatedAt" }
    ]
  }),
  computed: {
    ...mapGetters("user", ["getProfileAdmin"])
  },
  methods: {
    async getOrders() {
      this.loading = true;
      Object.assign(this.orders, await ReferalsService.getOrders());
      this.loading = false;
    },
    successOrder(order) {
      ReferalsService.successOrder(order.id, this.getProfileAdmin).then(
        async data => {
          if (data.success) {
            this.$noty.success("Заявка обработанна");
            await this.getOrders();
          } else {
            let msg = null;
            switch (data.err.code) {
              case 0:
                msg = "Заявка не найдена";
                break;
              case 1:
                msg = "Админ не найден";
                break;
              case 2:
                msg = "Заявка уже обработанна";
                break;
              default:
                msg = JSON.stringify(data);
                break;
            }
            this.$noty.error(msg);
          }
        }
      );
    },
    async canceledOrders(order) {
      ReferalsService.canceledOrders(order.id, this.getProfileAdmin).then(
        async data => {
          if (data.success) {
            this.$noty.success("Заявка обработанна");
            await this.getOrders();
          } else {
            let msg = null;
            switch (data.err.code) {
              case 0:
                msg = "Заявка не найдена";
                break;
              case 1:
                msg = "Админ не найден";
                break;
              case 2:
                msg = "Заявка уже обработанна";
                break;
              default:
                msg = JSON.stringify(data);
                break;
            }
            this.$noty.error(msg);
          }
        }
      );
    }
  },
  async created() {
    await this.getOrders();
  }
};
</script>

<style scoped></style>
