import { api } from "@/instances/http";

class referalsService {
  getOrders() {
    return api.post("/referals/getReferalOrders").then(res => {
      return res.data;
    });
  }
  successOrder(orderId, adminId) {
    return api
      .post("/referals/successReferalOrder", { orderId, adminId })
      .then(res => {
        return res.data;
      });
  }
  canceledOrders(orderId, adminId) {
    return api
      .post("/referals/canceledReferalOrder", { orderId, adminId })
      .then(res => {
        return res.data;
      });
  }
}

export default new referalsService();
